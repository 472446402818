import { useTheme } from "@mui/material";
import Fab from "@mui/material/Fab";
import Tooltip from "ds/components/Tooltip";
import { margin } from "ds/materials/metrics";
import { SHADOW_BUTTON, SHADOW_BUTTON_HOVER } from "ds/materials/shadows";
import { useCallback } from "react";
import styled from "styled-components";
import { hoverBackgroundColorSelector } from "./RSButton";
import { ColorData, backgroundColors, setInvertion } from "./colors";
import { RSFABProps } from "./index";

const FABWrapper = styled.div`
  margin: ${margin(0)};
`;

const Opacity = styled.span`
  display: flex;
  align-items: center;
  .MuiSvgIcon-root {
    opacity: 0.6;
  }
`;

export default function RSFAB({
  ariaLabel,
  buttonTestId,
  children,
  className,
  color = "default",
  disabled,
  id,
  inverted,
  onClick,
  size = "small",
  style,
  sx,
  testId,
  tooltip,
  type,
  variant,
}: RSFABProps) {
  const theme = useTheme();
  const shouldGetOpacity = variant === "extended" && inverted;

  const getSx = useCallback(() => {
    {
      // setup base colors
      let colorData: ColorData = {
        color:
          color === "secondary"
            ? theme.palette.common.black
            : theme.palette.common.white,
        backgroundColor: backgroundColors(theme)[color],
        boxShadow: SHADOW_BUTTON,
      };

      // setup invertion
      colorData = setInvertion(colorData, variant, inverted);

      // setup hover color
      colorData = {
        ...colorData,
        "&:hover": {
          backgroundColor:
            backgroundColors(theme)[
              hoverBackgroundColorSelector(color, inverted)
            ],
          boxShadow: SHADOW_BUTTON_HOVER,
        },
      };

      return colorData;
    }
  }, [color, variant, inverted, disabled, theme]);

  return (
    <FABWrapper data-testid={testId} style={style}>
      <Tooltip title={tooltip}>
        <span>
          <Fab
            aria-label={ariaLabel}
            className={className}
            data-testid={buttonTestId}
            disabled={disabled}
            id={id}
            onClick={onClick}
            size={size}
            sx={{ ...getSx(), ...sx }}
            variant={variant}
            type={type}
          >
            {shouldGetOpacity ? <Opacity>{children}</Opacity> : <>{children}</>}
          </Fab>
        </span>
      </Tooltip>
    </FABWrapper>
  );
}
