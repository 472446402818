// Add data types to window.navigator for use in this file.
/// <reference types="user-agent-data-types" />
import {
  IGNORE_PATHS,
  PARAM,
  PARAMS,
  PATHNAME_MAPPING,
} from "@recare/core/consts/pages";
import { PlatformInfo } from "@recare/core/validationSchemas/tracking";
import Bowser from "bowser";
import { useEffect, useState } from "react";
import { APP_VERSION_NAMES } from "../../consts";
import { AppType } from "../../types";
import Config from "../config";

export type Match<P = AnyObject> = {
  isExact: boolean;
  params: P;
  path: string;
  url: string;
} | null;

export function useGetPlatformInfo({ app }: { app: AppType }): PlatformInfo {
  const [model, setModel] = useState<string | null>(null);
  const { browser, os, platform } = Bowser.parse(window.navigator.userAgent);

  useEffect(() => {
    (async () => {
      const dataValues =
        await window.navigator.userAgentData?.getHighEntropyValues(["model"]);

      if (dataValues?.model) {
        setModel(dataValues.model);
      }
    })();
  }, []);

  return {
    application: APP_VERSION_NAMES[app],
    browser_version: browser.version ?? null,
    browser: browser.name ?? null,
    build: null,
    device_id: null,
    device_screen_height: window.screen.height ?? null,
    device_screen_width: window.screen.width ?? null,
    env: Config.environment ?? null,
    hostname: window.location.hostname ?? null,
    model,
    operation_system_version: os?.version ?? null,
    operation_system: os?.name ?? null,
    platform: platform?.type ?? null,
    version: Config.version ?? null,
  };
}

function getExactPathRegex(str: string): string {
  Object.values(PARAMS).forEach((param) => {
    str = str.replace(new RegExp(param, "g"), PARAM);
  });
  return `^${str}$`;
}

export function mapPathnameToPage(pathname: string): {
  pageName: string | null;
  staticUrl: string | null;
} {
  for (const key in PATHNAME_MAPPING) {
    const exactPathRegex = getExactPathRegex(key);
    if (pathname.match(exactPathRegex)) {
      return {
        pageName: PATHNAME_MAPPING[key as keyof typeof PATHNAME_MAPPING],
        staticUrl: key,
      };
    }
  }

  const isACP = pathname.startsWith("/acp");

  if (!isACP && !IGNORE_PATHS.includes(pathname)) {
    console.warn(
      `A pathname has been detected that is not defined in PATHNAME_MAPPING: ${pathname}.
Please be aware that this may be intentional`,
    );
  }

  return { pageName: null, staticUrl: null };
}

export function useGetPageName() {
  return mapPathnameToPage(location.pathname);
}
