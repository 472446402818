import { Theme, darken, lighten } from "@mui/material/styles";
import { ICON_DARK, TEXT_DARK_PRIMARY, WHITE } from "ds/materials/colors";
import { RSButtonVariant, RSFABVariant } from "./index";

export const backgroundColors = (theme: Theme) => ({
  default: TEXT_DARK_PRIMARY,
  "default-dark": darken(TEXT_DARK_PRIMARY, 0.12),
  "default-light": lighten(TEXT_DARK_PRIMARY, 0.7),
  primary: theme.palette.primary.main,
  "primary-light": theme.palette.primary.light,
  "primary-dark": theme.palette.primary.dark,
  secondary: theme.palette.secondary.main,
  "secondary-light": theme.palette.secondary.light,
  "secondary-dark": theme.palette.secondary.dark,
  success: theme.palette.success.main,
  "success-light": theme.palette.success.light,
  "success-dark": theme.palette.success.dark,
  error: theme.palette.error.main,
  "error-light": theme.palette.error.light,
  "error-dark": theme.palette.error.dark,
  grey: ICON_DARK,
  "grey-dark": darken(ICON_DARK, 0.12),
  "grey-light": lighten(ICON_DARK, 0.7),
  white: WHITE,
  "white-dark": darken(WHITE, 0.12),
  "white-light": lighten(WHITE, 0.7),
});

export type ColorData = {
  "&:hover"?: {
    backgroundColor: string;
    borderColor?: string;
    boxShadow?: string;
    color?: string;
    outline?: string;
  };
  backgroundColor: string;
  borderColor?: string;
  borderWidth?: string;
  boxShadow?: string;
  color: string;
};

export function setInvertion(
  colorData: ColorData,
  variant?: RSButtonVariant | RSFABVariant,
  inverted?: boolean,
) {
  const normalizedColorData = { ...colorData };
  const isInverted =
    variant === "text" || variant === "outlined" ? !inverted : inverted;
  if (isInverted) {
    normalizedColorData.color = colorData.backgroundColor;
    normalizedColorData.backgroundColor = colorData.color;
  }
  return normalizedColorData;
}
